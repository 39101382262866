<template>
  <BotFlow v-if="botid" :botid="botid" :config="{review: true}"></BotFlow>
</template>

<script>
import BotFlow from '../../../views/conserve/BotFlow.vue';
export default {
  name: 'bots_view',
  // props: ['props'],
  data: function () {
    return {
      botid: "",
      project: "",
    }
  },
  components: {
    BotFlow
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
  methods: {
    refreshData() {
      this.botid = this.$route.params.botID;
      // console.log(this.botid);
    },
  },
  watch: {
  }
}
</script>
